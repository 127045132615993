
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import EditLayerTransportationProductionProcessModal from '../modals/editLayerTransportationProductionProcessModal.vue';
import store from '@/store';
import utils from '@/utils';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class editLayerTransportation extends Vue {
    
    @Prop() estimationIdentifier: string;
    @Prop() saveToGarmentConfiguration: boolean;
    @Prop() productionPhase: string;
    @Prop() layer: OM.LayerComposition;
    @Prop() currentTransportation: OM.TransportProductionPhase[];
    @Prop() countries: OM.TextIdentifier[];
    @Prop() transportDistanceCalculation: OM.TransportDistanceCalculationVM[];
    @Prop() transportTypes: string[];
    @Prop() saveCallback: (editLayerTransportationVM : OM.EditLayerTransportationVM) => void;
    @Prop() removeCallback: (item: OM.RemoveLayerTransportationVM) => void;

    @Prop() rawMaterialCallToAction: boolean = false;

    hideEditAction: boolean = false; 

    created() {
        this.hideEditAction = utils.checkIfProductionPhaseIsNextFabric(this.productionPhase);
    }

    get disabled() {
        return this.filteredCurrentTransportation.length == this.layer.fabricCompositions.length;
    }

    async editTransportation(index: number) {
        var item = new OM.TransportProductionPhase();
        if(index != -1)
            item = this.filteredCurrentTransportation[index];

        this.$opModal.show(EditLayerTransportationProductionProcessModal, {
            estimationIdentifier: this.estimationIdentifier,
            productionPhase: this.productionPhase,
            layer: this.layer,
            editModel: item,
            currentTransportation: this.filteredCurrentTransportation,
            transportDistanceCalculation: this.transportDistanceCalculation,
            countries: this.countries,
            rawMaterialCallToAction: this.rawMaterialCallToAction,
            saveCallback: async (editLayerTransportationVM : OM.EditLayerTransportationVM, materials: OM.FabricComposition[]) => {
                editLayerTransportationVM.indexForEdit = index;

                if(this.rawMaterialCallToAction){
                    this.saveCallback(editLayerTransportationVM);
                }
                else if(this.hideEditAction){
                    var newEditLayerTransportationVM = {...editLayerTransportationVM};
                    await store.state.estimationClient.editAllLayerTransportationByProductionPhase(newEditLayerTransportationVM);
                    this.saveCallback(editLayerTransportationVM);
                }
                else {
                    var ris = await this.saveTransportForEveryMaterial(editLayerTransportationVM, materials);
                    this.saveCallback(editLayerTransportationVM);
                }
            }
        })
    }

    async saveTransportForEveryMaterial(editLayerTransportationVM : OM.EditLayerTransportationVM, materials: OM.FabricComposition[]) {        
        for(var i = 0; i < materials.length; i++) {
            var newEditLayerTransportationVM = {...editLayerTransportationVM};
            newEditLayerTransportationVM.fabricComposition = materials[i]; 
            await store.state.estimationClient.editLayerTransportation(newEditLayerTransportationVM);
        }
    }

    get filteredCurrentTransportation(){
        if(!this.currentTransportation)
            return [];

        return this.currentTransportation.filter( x => {
            return x.productionPhase == this.productionPhase
        })
    }

    removeTransportation(item: OM.TransportProductionPhase){
        var index = this.currentTransportation.indexOf(item);
        
        var deleteModel = new OM.RemoveLayerTransportationVM();
        deleteModel.identifier = this.estimationIdentifier;
        deleteModel.layerName = this.layer.layerName;
        deleteModel.index = index;

        this.removeCallback(deleteModel);
    }
}
